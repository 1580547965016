<template>
  <div class="trackCreat">
    <div class="trackItem">
      <div class="title">预算及排期</div>
      <div class="formWrap">
        <el-form
          label-width="140px"
          :model="yusuan"
        >
          <el-form-item
            label="广告系列预算优化："
            class="blod"
          >
            <el-switch
              v-model="adSet.is_cbo"
              :disabled="creatAdSet || !isCustomSale"
              @change="selectCbo"
            ></el-switch>
            <p class="tips">
              启用广告系列预算优化(CBO)，系统会将广告系列预算根据广告组的实时表现分配给广告组，以获得更多转化。
            </p>
          </el-form-item>
          <el-form-item
            :label="`广告${adSet.is_cbo ? '系列' : '组'}预算：`"
            class="blod"
            v-if="!adSet.is_cbo || !creatAdSet"
          >
            <el-input
              v-model="budget"
              @change="budgetBlur"
              @input="budgetBlur"
            >
              <el-select
                v-model="adSet.budget_type"
                slot="prepend"
                placeholder="请选择"
                @change="selectBudgetType"
              >
                <el-option
                  label="单日预算"
                  value="daily"
                ></el-option>
                <el-option
                  label="总预算"
                  value="lifetime"
                ></el-option>
              </el-select>
              <el-button slot="append">{{ currencyName }}</el-button>
            </el-input>
          </el-form-item>
          <el-form-item
            label="广告组花费限额："
            v-show="adSet.is_cbo"
          >
            <el-checkbox v-model="adsChecked">广告组花费限额</el-checkbox>
            <div
              class="daily_spend_cap"
              v-show="adsChecked"
            >
              <div class="l">
                <p>{{ adSet.budget_type == 'daily' ? '单日' : '总' }}花费下限</p>
                <el-input
                  v-if="adSet.budget_type == 'daily'"
                  placeholder="请输入单日花费下限"
                  type="number"
                  v-model="adSet.daily_min_spend_target"
                  :min="0"
                  ><template slot="append">$</template></el-input
                >
                <el-input
                  v-else
                  placeholder="请输入总花费下限"
                  type="number"
                  v-model="adSet.lifetime_min_spend_target"
                  :min="0"
                  ><template slot="append">$</template></el-input
                >
                <span>我们无法保证花费金额与花费下限完全吻合</span>
              </div>
              <div class="r">
                <p>{{ adSet.budget_type == 'daily' ? '单日' : '总' }}花费上限</p>
                <el-input
                  v-if="adSet.budget_type == 'daily'"
                  placeholder="请输入单日花费上限"
                  type="number"
                  v-model="adSet.daily_spend_cap"
                  :min="0"
                  ><template slot="append">$</template></el-input
                >
                <el-input
                  v-else
                  placeholder="请输入总花费上限"
                  type="number"
                  v-model="adSet.lifetime_spend_cap"
                  :min="0"
                  ><template slot="append">$</template></el-input
                >
                <span>你的花费不会超过这个金额</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="投放优化目标："
            v-if="initData.ad"
          >
            <el-select
              v-model="adSet.optimization_goal"
              placeholder="请选择"
              @change="setTarget"
            >
              <el-option
                :label="item.desc"
                :value="item.key"
                v-for="(item, index) in optimizationGoals"
                :key="index"
              ></el-option>
            </el-select>
            <span
              class="tips ml-2"
              v-if="adSet.optimization_goal == 'VALUE'"
              >优化目标为价值时，像素事件将会重置为“购买”</span
            >
          </el-form-item>
          <el-form-item label="投放策略：">
            <span class="celue">
              <span>{{
                adSet.bid_strategy == 'LOWEST_COST_WITHOUT_CAP'
                  ? '自动竞价'
                  : adSet.bid_strategy == 'COST_CAP'
                  ? '目标费用'
                  : adSet.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS'
                  ? '最低ROAS竞价'
                  : '手动出价'
              }}</span>
              <el-tooltip
                class="item"
                effect="light"
                content="使用出价上限竞价策略，Facebook 会尽可能获得最多展示次数，且在每次竞拍中，竞价金额不会超过$2，按展示次数计费"
                placement="top-start"
              >
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </span>
            <el-button
              type="text"
              @click="setStrategyShow = true"
              >调整</el-button
            >
          </el-form-item>
          <!-- 广告受益方 -->
          <el-form-item label="广告受益方：">
            <el-input
              v-model="dsa_beneficiary"
              placeholder="请输入广告收益方"
              @change="changeBeneficiary"
            ></el-input>
            <el-tooltip
              class="item"
              effect="dark"
              placement="bottom"
            >
              <p slot="content">
                欧盟包括：奥地利、比利时、保加利亚、塞浦路斯、克罗地亚、捷克、丹麦、爱沙尼亚、芬兰、法国、德国、希腊、匈牙利、爱尔兰、<br />意大利、拉脱维亚、立陶宛、卢森堡、马耳他、荷兰、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典
              </p>
              <span class="tips">欧盟必填</span>
            </el-tooltip>
          </el-form-item>
          <!-- 广告受益方 -->
          <el-form-item label="广告赞助方：">
            <el-input
              v-model="dsa_payor"
              placeholder="请输入广告赞助方"
              @change="changePayor"
            ></el-input>
            <el-tooltip
              class="item"
              effect="dark"
              placement="bottom"
            >
              <p slot="content">
                欧盟包括：奥地利、比利时、保加利亚、塞浦路斯、克罗地亚、捷克、丹麦、爱沙尼亚、芬兰、法国、德国、希腊、匈牙利、爱尔兰、<br />意大利、拉脱维亚、立陶宛、卢森堡、马耳他、荷兰、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典
              </p>
              <span class="tips">欧盟必填</span>
            </el-tooltip>
          </el-form-item>
          <el-form-item
            label="分时段投放："
            v-if="canDayPartaing"
          >
            <el-checkbox
              v-model="adSet.pacing_type"
              true-label="day_parting"
              false-label="standard"
              >是否分时段投放</el-checkbox
            >
          </el-form-item>
          <!-- 广告受益方 -->
          <!-- <el-form-item label="广告受益方：">
            <el-input v-model="adSet.dsa_beneficiary" placeholder="请输入广告收益方"></el-input>
            <el-tooltip class="item" effect="dark" placement="bottom">
                <p slot="content">欧盟包括：奥地利、比利时、保加利亚、塞浦路斯、克罗地亚、捷克、丹麦、爱沙尼亚、芬兰、法国、德国、希腊、匈牙利、爱尔兰、<br />意大利、拉脱维亚、立陶宛、卢森堡、马耳他、荷兰、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典</p>
                <span class="tips">欧盟必填</span>
            </el-tooltip>
        </el-form-item> -->
          <!-- 广告受益方 -->
          <!-- <el-form-item label="广告赞助方：">
            <el-input v-model="adSet.dsa_payor" placeholder="请输入广告赞助方"></el-input>
            <el-tooltip class="item" effect="dark" placement="bottom">
                <p slot="content">欧盟包括：奥地利、比利时、保加利亚、塞浦路斯、克罗地亚、捷克、丹麦、爱沙尼亚、芬兰、法国、德国、希腊、匈牙利、爱尔兰、<br />意大利、拉脱维亚、立陶宛、卢森堡、马耳他、荷兰、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典</p>
                <span class="tips">欧盟必填</span>
            </el-tooltip>
        </el-form-item> -->
        </el-form>
      </div>
    </div>
    <div class="trackItem">
      <div class="title">追踪及拆分</div>
      <div class="formWrap">
        <el-form
          label-width="140px"
          :model="yusuan"
        >
          <el-form-item label="设置转化追踪：">
            <el-checkbox
              label="Facebook Pixel像素代码"
              value="1"
              v-model="pixexCode"
              disabled
            ></el-checkbox>

            <el-select
              v-model="adSeriesInfo.fbPixelId"
              placeholder="请选择"
              style="margin-left: 30px"
              disabled
            >
              <el-option
                :label="item.name"
                :value="item.pixel_id"
                v-for="(item, index) in pixelList"
                :key="index"
              ></el-option>
            </el-select>
            <span style="margin: 8px; color: #999">当前像素id：{{ adSeriesInfo.fbPixelId }}</span>
          </el-form-item>

          <el-form-item
            label="按受众拆分："
            v-if="splitList && splitList.length > 0"
          >
            <el-checkbox-group v-model="splitBy">
              <el-checkbox
                :value="item.id"
                v-for="item in splitList"
                :key="item.id"
                :label="item.id"
                >{{ item.name }} {{ item.num }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="">
            <!-- <el-button
              type="text"
              @click="complainConfig('webConfigShow')"
              >网址追踪设置</el-button
            > -->
            <el-button
              type="text"
              @click="complainConfig('nameConfigShow')"
              >自动命名设置</el-button
            >
            <el-button
              type="text"
              @click="complainConfig('adConfigShow')"
              >广告结构设置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="trackItem">
        <div class="title">投放及预览</div>
        <div class="formWrap">
          <p>根据你的预算排期和受众拆分，自动生成以下广告组和广告，你可以单独设置广告组排期和预算：</p>
          <div style="display: flex; align-items: center; justify-content: space-between; margin: 10px 0">
            <div
              class="series"
              v-if="!campaignId"
            >
              广告系列名称：
              <span>
                <el-input
                  style="width: 70%; margin-right: 10px"
                  v-model="campaign_name"
                  @input="inputCampaignName"
                ></el-input>
              </span>
            </div>
            <span v-if="campaignId"></span>
            <div class="nameBtn">
              <el-tooltip
                effect="dark"
                content="批量开启广告组"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  @click="editStatus('ACTIVE')"
                  :disabled="!selectList.length"
                >
                  开启
                </el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="批量暂停广告组"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  @click="editStatus('PAUSED')"
                  :disabled="!selectList.length"
                >
                  暂停
                </el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="批量修改广告组名称"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  @click="editName('adset')"
                  type="text"
                  :disabled="!selectList.length"
                >
                  <img
                    src="../../../../assets/icon/editGroup.png"
                    alt=""
                  />
                </el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="批量修改广告名称"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  @click="editName('ad')"
                  type="text"
                  :disabled="!selectList.length"
                >
                  <img
                    src="../../../../assets/icon/editAd.png"
                    alt=""
                  />
                </el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="批量修改广告组排期"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  @click="editDateShow = true"
                  type="text"
                  :disabled="!selectList.length"
                >
                  <img
                    src="../../../../assets/icon/editDate.png"
                    alt=""
                  />
                </el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="批量修改广告组投放时段"
                placement="top-start"
                v-if="dayParting"
              >
                <el-button
                  size="mini"
                  @click="setTimePickerBtn(false)"
                  type="text"
                  :disabled="!selectList.length"
                >
                  <img
                    src="../../../../assets/icon/editTimes.png"
                    alt=""
                  />
                </el-button>
              </el-tooltip>
            </div>
          </div>
          <div>
            <el-table
              :data="tableData"
              style="width: 100%; margin-bottom: 20px"
              border
              :tree-props="defaultProps"
              :span-method="objectSpanMethod"
              :indent="10"
              :show-summary="true"
              sum-text=" "
              :summary-method="getSummaries"
              row-key="index"
              @selection-change="selectAds"
            >
              <el-table-column
                type="selection"
                width="55"
                :selectable="canSelect"
              ></el-table-column>
              <el-table-column
                prop="date"
                label="广告组名称"
                width="390"
              >
                <template slot-scope="scope">
                  <el-image
                    v-if="scope.row.image_url && scope.row.media_type == 'image'"
                    :src="scope.row.image_url"
                    style="width: 40px; height: 40px; margin-right: 10px"
                  ></el-image>
                  <video
                    v-if="scope.row.image_url && scope.row.media_type == 'video'"
                    :src="scope.row.image_url"
                    style="width: 40px; height: 40px; margin-right: 10px"
                  ></video>
                  <span v-if="!scope.row.visible">{{ scope.row.name }}</span>
                  <el-input
                    v-model="scope.row.name"
                    v-else
                    @blur="scope.row.visible = false"
                  ></el-input>
                  <el-button
                    type="text"
                    @click="scope.row.visible = true"
                  >
                    <i class="el-icon-edit"></i
                  ></el-button>
                  <!-- <el-popover  placement="top"  width="260" v-model="scope.row.visible">
                                        <p style="margin-bottom:10px;"><el-input v-model="scope.row.name"></el-input></p>
                                        <div style="text-align: right; margin: 0">
                                            <el-button size="mini" type="text" @click="scope.row.visible=false">取消</el-button>
                                            <el-button type="primary" size="mini" @click="scope.row.visible=false">确定</el-button>
                                        </div>
                                        <el-button slot="reference" type="text"> <i class="el-icon-edit"></i></el-button>
                                    </el-popover> -->
                </template>
              </el-table-column>
              <el-table-column
                prop="status"
                label="广告组状态"
                width="240"
              >
                <template
                  slot-scope="scope"
                  v-if="scope.row.ads"
                >
                  <el-radio-group v-model="scope.row.status">
                    <el-radio label="">默认</el-radio>
                    <el-radio label="ACTIVE">开启</el-radio>
                    <el-radio label="PAUSED">关闭</el-radio>
                  </el-radio-group>
                  <!-- <el-select v-model="scope.row.status" placeholder="">
                                        <el-option label="默认" value=""></el-option>
                                        <el-option label="开启" value="ACTIVE"></el-option>
                                        <el-option label="关闭" value="PAUSED"></el-option>
                                    </el-select> -->
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                label="广告组排期"
                width="320"
              >
                <template
                  slot-scope="scope"
                  v-if="scope.row.dateType"
                >
                  <div>
                    <el-row style="margin-bottom: 10px">
                      <el-col
                        :span="6"
                        style="line-height: 40px"
                        >开始时间：</el-col
                      >
                      <el-col :span="18">
                        <el-date-picker
                          v-model="scope.row.dateGroup[0]"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="选择开始日期"
                        >
                        </el-date-picker>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col
                        :span="6"
                        style="line-height: 40px"
                        >结束时间：</el-col
                      >
                      <el-col :span="18">
                        <el-date-picker
                          v-model="scope.row.dateGroup[1]"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="选择结束日期"
                        >
                        </el-date-picker>
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                label="受众人群(万)"
                width="180"
              >
                <template
                  slot-scope="scope"
                  v-if="scope.row.users"
                >
                  <div>
                    <el-popover
                      placement="left"
                      width="300"
                      trigger="hover"
                    >
                      <div
                        class="previews"
                        v-if="scope.row.targeting"
                      >
                        <draw-gauge :users="scope.row.users"></draw-gauge>
                        <div class="savePeople">
                          <div class="peopleItem">
                            <span>地区</span>
                            <div>
                              {{ scope.row.targeting[0].sentenceLines.geo_locations }}
                            </div>
                          </div>
                          <div class="peopleItem">
                            <span>语言</span>
                            <div>
                              {{ scope.row.targeting[0].sentenceLines.locales }}
                            </div>
                          </div>
                          <div class="peopleItem">
                            <span>兴趣</span>
                            <div>
                              {{ scope.row.targeting[0].sentenceLines.flexible_spec }}
                            </div>
                          </div>
                          <div class="peopleItem">
                            <span>性别</span>
                            <div>
                              {{ scope.row.targeting[0].sentenceLines.genders }}
                            </div>
                          </div>
                          <div class="peopleItem">
                            <span>年龄组</span>
                            <div>
                              {{ scope.row.targeting[0].sentenceLines.age_range }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <span slot="reference"><i class="el-icon-s-custom"></i>{{ scope.row.users / 10000 }}</span>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                :label="'广告组预算 (' + currencyName + ')'"
                width="300"
              >
                <template
                  slot-scope="scope"
                  v-if="scope.row.ads"
                >
                  <div>
                    <div v-if="!scope.row.editYusuan">
                      <div v-if="!adSet.is_cbo">
                        <span>{{ scope.row.budget }}</span>
                        <el-button
                          type="text"
                          @click="editYusuan(scope.$index, scope.row)"
                          ><i class="el-icon-edit"></i
                        ></el-button>
                        <p class="tips">平均分配</p>
                      </div>
                      <div v-if="adSet.is_cbo">
                        <span
                          >{{
                            scope.row.daily_min
                              ? scope.row.daily_min
                              : adSet.daily_min_spend_target
                              ? adSet.daily_min_spend_target
                              : '最低下限'
                          }}--{{
                            scope.row.daily_max
                              ? scope.row.daily_max
                              : adSet.daily_spend_cap
                              ? adSet.daily_spend_cap
                              : '最高上限'
                          }}</span
                        >
                        <el-button
                          type="text"
                          @click="editYusuan(scope.$index, scope.row)"
                          ><i class="el-icon-edit"></i
                        ></el-button>
                        <p class="tips">使用广告系列预算</p>
                      </div>
                    </div>
                    <div v-if="scope.row.editYusuan">
                      <el-input
                        v-model="scope.row.budget"
                        @change="saveYusuan(scope.row.budget, scope.$index, scope.row)"
                        @blur="saveYusuan(scope.row.budget, scope.$index, scope.row)"
                        v-if="!adSet.is_cbo"
                        type="number"
                      >
                        <el-button slot="append">{{ currencyName }}</el-button>
                      </el-input>
                      <div v-if="adSet.is_cbo">
                        <el-input
                          v-model="scope.row.daily_min"
                          style="width: 40%"
                        ></el-input>
                        <span style="margin: 0 2px">--</span>
                        <el-input
                          v-model="scope.row.daily_max"
                          style="width: 40%"
                        ></el-input>
                        <el-button
                          type="text"
                          @click="saveYusuanGroup(scope.row.daily_min, scope.row.daily_max, scope.$index, scope.row)"
                          style="margin-left: 5px"
                          size="mini"
                          >确定</el-button
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                :label="'竞价策略 (' + currencyName + ')'"
                width="240"
              >
                <template
                  slot-scope="scope"
                  v-if="scope.row.targeting"
                >
                  <!-- 出价上限 -->
                  <div
                    v-if="scope.row.bid_strategy == 'LOWEST_COST_WITH_BID_CAP' || scope.row.bid_strategy == 'COST_CAP'"
                  >
                    <div v-if="!scope.row.editShangxian">
                      <div>
                        <span>{{ scope.row.bid_amount }}</span>
                        <el-button
                          type="text"
                          @click="editShangxian(scope.$index, scope.row)"
                          ><i class="el-icon-edit"></i
                        ></el-button>
                        <p class="tips">
                          {{ scope.row.bid_strategy == 'LOWEST_COST_WITH_BID_CAP' ? '手动出价' : '目标费用' }}({{
                            goalsName(scope.row.optimization_goal)
                          }})
                        </p>
                      </div>
                    </div>
                    <div v-if="scope.row.editShangxian">
                      <el-input
                        v-model="scope.row.bid_amount"
                        @change="saveShangxian(scope.row.bid_amount, scope.$index, scope.row, 'bid_amount')"
                        @blur="saveShangxian(scope.row.bid_amount, scope.$index, scope.row, 'bid_amount')"
                        type="number"
                      >
                        <el-button slot="append">{{ currencyName }}</el-button>
                      </el-input>
                    </div>
                  </div>
                  <!-- 最低roas -->
                  <div v-else-if="scope.row.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS'">
                    <div v-if="!scope.row.editShangxian">
                      <div>
                        <span>{{ scope.row.roas_average_floor }}</span>
                        <el-button
                          type="text"
                          @click="editShangxian(scope.$index, scope.row)"
                          ><i class="el-icon-edit"></i
                        ></el-button>
                        <p class="tips">最低roas({{ goalsName(scope.row.optimization_goal) }})</p>
                      </div>
                    </div>
                    <div v-if="scope.row.editShangxian">
                      <el-input
                        v-model="scope.row.roas_average_floor"
                        @change="
                          saveShangxian(scope.row.roas_average_floor, scope.$index, scope.row, 'roas_average_floor')
                        "
                        @blur="
                          saveShangxian(scope.row.roas_average_floor, scope.$index, scope.row, 'roas_average_floor')
                        "
                        type="number"
                      >
                      </el-input>
                    </div>
                  </div>
                  <!-- 自动竞价 -->
                  <div v-else>
                    <div>
                      <!-- <span>{{ scope.row.bid_amount }}</span> -->
                      <p class="tips">自动竞价({{ goalsName(scope.row.optimization_goal) }})</p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                label="投放时段"
                width="240"
                v-if="dayParting"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    content="修改广告组投放时段"
                    placement="top-start"
                    v-if="dayParting"
                  >
                    <el-button
                      size="mini"
                      @click="setTimePickerBtn(scope.row, scope.$index)"
                      type="text"
                    >
                      <img
                        src="../../../../assets/icon/editTimes.png"
                        alt=""
                        style="width: 26px; height: 26px"
                      />
                    </el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div class="block">
                            <el-pagination
                                layout="prev, pager, next"
                                :total="50">
                            </el-pagination>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 设置投放策略 -->
    <bid-amount
      :adSetInfo="adSet"
      :setStrategyShow.sync="setStrategyShow"
      :targetTypeList="targetTypeList"
      :initData="initData"
      :adSeriesInfo="adSeriesInfo"
      @setBidStrategy="setBidStrategy"
      @onChageBidAmount="onChageBidAmount"
      @setStrategy="setStrategy"
      :isCustomSale="isCustomSale"
      :isFbPageAd="isFbPageAd"
      :attributionSpec="attributionSpec"
    ></bid-amount>
    <!-- 网页追踪设置 -->
    <web-config
      :webConfigShow="webConfigShow"
      @close="close"
      :dictsInit="dictsInit"
      @setWebsitUrl="setWebsitUrl"
      :fbAccountId="fbAccountId"
      :accountSet="accountSet"
    ></web-config>
    <name-config
      :nameConfigShow="nameConfigShow"
      @close="close"
      :dictsInit="dictsInit"
      :fbAccountId="fbAccountId"
      :accountSet="accountSet"
      @setName="setName"
    ></name-config>
    <ad-config
      :adConfigShow="adConfigShow"
      @close="close"
      :dictsInit="dictsInit"
      :fbAccountId="fbAccountId"
      :accountSet="accountSet"
      @setsplitType="setsplitType"
    ></ad-config>
    <edit-ad-name
      :editNameShow="editNameShow"
      @close="close"
      :nameLevel="nameLevel"
      @get-name="getName"
    ></edit-ad-name>
    <edit-ad-date
      :editDateShow="editDateShow"
      @close="close"
      :budgetType="adSet.budget_type"
      @get-date="getDate"
    ></edit-ad-date>
    <slect-times-drag
      :setTimePickerShow.sync="setTimePickerShow"
      :value="timesList"
      @setTimePicker="setTimePicker"
      @sure="setCurrentTimes"
    ></slect-times-drag>
  </div>
</template>
<script>
import audiencePreviews from './audiencePreviews';
import webConfig from './webConfig';
import nameConfig from './nameConfig';
import adConfig from './adConfig';
import {
  getSetsByAccounts,
  getDicts,
  accountPixel,
  splitView,
  splitNum,
  queryConfigAdBeneficiary,
} from '@/api/creatAd.js';
import { adsetForCreate } from '@/api/adManagement.js';
import { fnCSTToYmd, fnMsToCST } from '@/utils/utils';
import DrawGauge from './targeting/DrawGauge';
import EditAdName from './editAdName.vue';
import EditAdDate from './editAdDate';
import slectTimesDrag from './selectTimeDrag/index.vue';
import tools from './tools.js';
import bidAmount from './trackCreatComponents/bidAmount';
import { mapGetters } from 'vuex';
export default {
  mixins: [tools],
  props: [
    'accountId',
    'initData',
    'adSeriesInfo',
    'adsInfo',
    'audienceInfo',
    'activeStep',
    'pixelList',
    'campaignInfo',
  ],
  // props:{
  //     accountId:{
  //         type:Number,
  //         default:0
  //     },
  //     initData:{
  //         type:Object,
  //         default: () => {}
  //     },
  //     adSeriesInfo:{
  //         type:Object,
  //         default: () => {}
  //     }
  // },
  data() {
    return {
      yusuan: {},
      splitBy: [],
      pixexCode: true,
      webConfigShow: false,
      nameConfigShow: false,
      adConfigShow: false,
      fbAccountId: '',
      dictsInit: {},
      targetList: [
        { name: '转化量', id: 1 },
        { name: '落地页浏览量', id: 2 },
        { name: '链接点击量', id: 3 },
        { name: '展示次数', id: 4 },
        { name: '单日独立覆盖人数', id: 5 },
      ],
      tableData: [],
      visible: false,
      setStrategyShow: false,
      budget: this.$store.getters.toFormatedMoney(4000).value,
      adSet: {
        bid_strategy: 'LOWEST_COST_WITHOUT_CAP',
        budget: this.$store.getters.toFormatedMoney(4000).value,
        budget_type: 'daily',
        billing_event: 'IMPRESSIONS',
        bid_amount: '',
        optimization_goal: 'OFFSITE_CONVERSIONS',
        attribution_spec: null,
        is_cbo: false,
        pacing_type: 'standard',
        daily_min_spend_target: null,
        daily_spend_cap: null,
      },
      rules: {},
      dataHelp: {},
      accountSet: {},
      nameObj: {},
      split_type: 1,
      targetTypeList: [
        {
          buyingTypes: [{ desc: '默认', key: 'AUCTION' }],
          desc: '展示量',
          key: 'IMPRESSIONS',
        },
      ],
      splitParmas: {},
      splitList: [],
      split: [],
      split_num: 1,
      campaign_name: '',
      new_campaign_name: '',
      defaultProps: {
        children: 'ads',
      },
      editNameShow: false,
      nameLevel: 'adset',
      campaignId: null,
      creatAdSet: false,
      editDateShow: false,
      editedCampaignName: false,
      setTimePickerShow: false,
      editTimesIndex: -1,
      timesList: [{ days: [0], end_minute: 90, from: '01:00', start_minute: 60, to: '01:30' }],
      selectList: [],
      adsChecked: false, // 广告组花费限额
      dsa_beneficiary: '',
      dsa_payor: '',
    };
  },
  components: {
    DrawGauge,
    audiencePreviews,
    webConfig,
    nameConfig,
    adConfig,
    EditAdName,
    EditAdDate,
    slectTimesDrag,
    bidAmount,
  },
  computed: {
    ...mapGetters('initData', ['getAttributionSpec']),
    isFbPageAd() {
      return this.adSeriesInfo.business == 'FACEBOOK_PAGE';
    },
    isCustomSale() {
      return this.adSeriesInfo.smart_promotion_type !== 'AUTOMATED_SHOPPING_ADS';
    },
    currencyName() {
      return this.$store.getters.currentUiUnit.symbol;
    },
    showMoney(v) {
      return this.$store.getters.toFormatedMoney(v).formated;
    },
    optimizationGoals() {
      if (this.initData.ad && this.adSeriesInfo.business) {
        let objectives = this.initData.ad.BusinessType.find((v) => v.key == this.adSeriesInfo.business).objectives;
        let optimizationGoal = objectives.find((v) => v.key == this.adSeriesInfo.objective);
        return [optimizationGoal.default_optimizationGoal].concat(optimizationGoal.other_valid_optimizationGoal);
      } else {
        return [];
      }
    },
    dayParting() {
      return !this.adSet.is_cbo && this.adSet.pacing_type == 'day_parting' && this.adSet.budget_type == 'lifetime';
    },
    canDayPartaing() {
      return !this.adSet.is_cbo && this.adSet.budget_type == 'lifetime';
    },
    attributionSpec() {
      return this.getAttributionSpec(this.adSet.optimization_goal);
    },
    // goalsName() {
    //   return this.optimizationGoals.find((v) => v.key == this.adSet.optimization_goal)?.desc;
    // },
  },
  methods: {
    debounce(fn, delay) {
      let timer = null; //借助闭包
      return function () {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(fn, delay); // 简化写法
      };
    },
    inputCampaignName(v) {
      this.editedCampaignName = true;
    },
    budgetBlur() {
      // console.log(this.debounce());
      let _this = this;
      this.debounce(function () {
        _this.adSet.budget = _this.budget = (_this.budget / 1).toFixed(2);
      }, 1000)();
    },
    goalsName(optimization_goal) {
      return this.optimizationGoals.find((v) => v.key == optimization_goal)?.desc;
    },
    changeBeneficiary(v) {
      if (this.getStrlength(v) > 50) {
        this.$message({
          type: 'info',
          message: '长度超过50个字节',
        });
      }
      this.adSet.dsa_beneficiary = this.dsa_beneficiary = this.subSliceStr(v);
      this.adSet.dsa_payor = this.dsa_payor = this.dsa_beneficiary;
      this.splitPreviews();
    },
    changePayor(v) {
      this.adSet.dsa_payor = this.dsa_payor = this.subSliceStr(v);
      this.splitPreviews();
    },
    // 获取字节长度
    getStrlength(str) {
      var count = 0;
      for (var i = 0; i < str.length; i++) {
        var c = str.charAt(i);
        if (/^[\u0000-\u00ff]$/.test(c)) {
          count++;
        } else {
          count += 2;
        }
      }
      return count;
    },
    // 截取字符串50个字节长度
    subSliceStr(str) {
      var count = 0;
      for (var i = 0; i < str.length; i++) {
        var c = str.charAt(i);
        if (/^[\u0000-\u00ff]$/.test(c)) {
          count++;
        } else {
          count += 2;
        }
        if (count > 50) {
          return str.substring(0, i);
        }
      }
      return str;
    },
    // 关闭
    close(v) {
      this[v] = false;
    },
    setStrategy(v) {
      this.adSet = { ...this.adSet, ...v };
      // if (
      //   (this.adSet.bid_strategy == 'COST_CAP' || this.adSet.bid_strategy == 'LOWEST_COST_WITH_BID_CAP') &&
      //   !this.adSet.bid_amount
      // ) {
      //   this.$message({
      //     type: 'warning',
      //     message: '选择目标费用或者手动出价的时候必须填写费用控制额',
      //   });
      //   return false;
      // }
      // if (this.adSet.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS' && !this.adSet.roas_average_floor) {
      //   this.$message({
      //     type: 'warning',
      //     message: '选择最低ROAS竞价的时候必须填写保底roas',
      //   });
      //   return false;
      // }
      // this.setStrategyShow = false;
    },
    setTimePickerBtn(row, index) {
      console.log(row);
      if (row) {
        this.editTimesIndex = row.index - 1;
        this.timesList = row?.adset_schedule || [];
      } else {
        this.editTimesIndex = -1;
        this.timesList = [];
      }
      this.setTimePickerShow = true;
    },
    // 设置广告组分时段投放
    setTimePicker(v) {
      this.timesList = JSON.parse(JSON.stringify(v));
    },
    // 设置广告组分时段投放字段的值
    setCurrentTimes() {
      if (this.editTimesIndex == -1) {
        // 批量编辑，所有的广告组都设置
        this.tableData.forEach((item) => {
          if (this.selectList.includes(item.index)) {
            item.adset_schedule = JSON.parse(JSON.stringify(this.timesList));
          }
        });
      } else {
        // 设置单个广告组
        this.tableData.map((v, index) => {
          if (index == this.editTimesIndex) {
            v.adset_schedule = JSON.parse(JSON.stringify(this.timesList));
          }
        });
      }
    },
    // 合并行
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (!column.children) {
        // return {
        //     // rowspan: 2,
        //     // colspan: 2
        // };
      }
    },
    // 编辑表格广告组、广告名称
    editName(level) {
      this.nameLevel = level;
      this.editNameShow = true;
    },
    // 获取编辑的名称
    getName(v) {
      if (this.nameLevel == 'adset') {
        this.tableData.forEach((item) => {
          if (this.selectList.includes(item.index)) {
            item.name = v;
          }
        });
      } else {
        this.tableData.map((item) => {
          item.ads.map((k) => {
            k.name = v;
          });
        });
      }
    },
    editStatus(v) {
      this.tableData.forEach((item) => {
        if (this.selectList.includes(item.index)) {
          item.status = v;
        }
      });
    },
    // 编辑表格广告组预算
    editYusuan(index, row) {
      console.log(index, row);
      row.editYusuan = true;
      // if(row.media_type){
      //     let indexs = row.index.split('-');
      //     this.$set(this.tableData[indexs[0]-1].ads,indexs[1],row)
      //     this.$set(this.tableData,indexs[0]-1,this.tableData[indexs[0]-1])
      // }else{
      //     this.$set(this.tableData,index,row)
      // }
      console.log(index);
      this.$set(this.tableData, row.index - 1, row);
    },
    // 保存预算
    saveYusuan(val, index, row) {
      // console.log(row);
      row.editYusuan = false;
      row.budget = (val / 1).toFixed(2);
      // if(row.media_type){
      //     let indexs = row.index.split('-');
      //     this.$set(this.tableData[indexs[0]-1].ads,indexs[1],row)
      //     this.$set(this.tableData,indexs[0]-1,this.tableData[indexs[0]-1])
      // }else{
      //     this.$set(this.tableData,index,row)
      // }
      // this.$forceUpdate();
      this.$set(this.tableData, row.index - 1, row);
    },
    // 保存预算上限下限
    saveYusuanGroup(val, val1, index, row) {
      // console.log(row);
      row.editYusuan = false;
      row.daily_min = val;
      row.daily_max = val1;
      this.$set(this.tableData, row.index - 1, row);
    },
    // 编辑表格广告组预算上限
    editShangxian(index, row) {
      row.editShangxian = true;
      // if(row.media_type){
      //     let indexs = row.index.split('-');
      //     this.$set(this.tableData[indexs[0]-1].ads,indexs[1],row)
      //     this.$set(this.tableData,indexs[0]-1,this.tableData[indexs[0]-1])
      // }else{
      //     this.$set(this.tableData,index,row)
      // }
      this.$set(this.tableData, row.index - 1, row);
      // this.$forceUpdate();
      // console.log(row);
    },
    // 保存预算上限
    saveShangxian(val, index, row, key) {
      // console.log(row);
      row.editShangxian = false;
      row[key] = (val / 1).toFixed(2);
      // if(row.media_type){
      //     let indexs = row.index.split('-');
      //     this.$set(this.tableData[indexs[0]-1].ads,indexs[1],row)
      //     this.$set(this.tableData,indexs[0]-1,this.tableData[indexs[0]-1])
      // }else{
      //     this.$set(this.tableData,index,row)
      // }
      this.$set(this.tableData, row.index - 1, row);
      //     this.$forceUpdate();
    },
    // 表格合计方法
    getSummaries() {
      // const { columns, data } = param;
      const sums = [];
      // sums[0] = '';
      sums[1] = `共生成个${this.tableData.length}广告组`;
      // sums[1] = '总排期：2020/11/20 10:06-2020/11/27 10:06';
      return sums;
    },
    // 获取选中的表格数据
    selectAds(v) {
      this.selectList = v.map((item) => item.index);
    },
    // 点击设置
    complainConfig(v) {
      this[v] = true;
    },
    // 获取广告账户默认设置
    async getAccountSet(v) {
      await getSetsByAccounts({ accounts: [v] }).then((res) => {
        // console.log('getSetsByAccounts',res);
        this.accountSet = res.data.list[0];
        // this.splitPreviews();
      });
    },
    // 获取命名规则字典项
    getDictsInit() {
      getDicts().then((res) => {
        // console.log(res);
        this.dictsInit = res.data;
      });
    },
    // 接收网页追踪参数设置
    setWebsitUrl(v) {
      this.dataHelp.website_url = v;
      this.accountSet.gg_analytics = v;
    },
    // 接收命名参数
    setName(v) {
      this.nameObj = v;
      this.accountSet = { ...this.accountSet, ...v };
      this.splitPreviews();
    },
    // 设置拆分方式
    setsplitType(v) {
      this.split_type = v;
      this.accountSet.split_type = v;
      this.splitPreviews();
    },
    // 选择优化目标
    setTarget(v) {
      //   if (!v) {
      //     const value = this.optimizationGoals[0].key;
      //     this.adSet.optimization_goal = value;
      //     v = value;
      //   }
      let list = this.initData.ad.AdSetOptimizationGoal.filter((k) => k.key == v);
      this.targetTypeList = list[0]?.billingEvents || [];
      this.adSet.attribution_spec = this.attributionSpec[0].key;
      this.adSet.bid_strategy = 'LOWEST_COST_WITHOUT_CAP';
      //   if (v !== 'VALUE') {
      //     this.adSet.bid_strategy = 'LOWEST_COST_WITHOUT_CAP';
      //   }
      //else{
      //     this.adSet.bid_strategy = 'LOWEST_COST_WITHOUT_CAP'
      // }
      // console.log(this.targetTypeList);
    },
    // 选择竞价策略
    setBidStrategy(v) {
      if (v == 'LOWEST_COST_WITH_MIN_ROAS') {
        this.adSet.optimization_goal = 'VALUE';
      }
    },
    formatedMoney(v) {
      return this.$store.getters.toFormatedMoney(v).formated;
      // return "$" + (parseFloat(v) / 100).toFixed(2);
    },
    // this.$store.getters.toBaseMoney
    selectDateType(v, index) {
      // console.log(v);
      this.$set(this.tableData[index], 'dateType', v);
      if (v == '1') {
        // console.log(this.tableData[index].start_date);
        this.tableData[index].setStart = true;
        this.tableData[index].end_date = null;
        this.tableData[index].dateGroup = [];
      } else {
        // console.log(111);
        this.setDateType(v, index);
      }
      // this.tableData[index].dateType = v
      // console.log(this.tableData[index]);
      this.$set(this.tableData, index, this.tableData[index]);
      // this.$forceUpdate();
      // console.log(v);
    },
    // 选择广告组日预算，分时段投放取消
    selectBudgetType(v) {
      if (v == 'daily') {
        this.pacing_type = 'standard';
      }
    },
    // 选择广告系列预算优化，分时段投放取消
    selectCbo(v) {
      if (v) {
        this.pacing_type = 'standard';
      }
    },
    setDateType(v, index) {
      // console.log(index);
      // this.tableData[index].dateType = v;
      this.$set(this.tableData[index], 'dateType', v);
      // console.log(this.tableData[index]);
      if (v == 1) {
        this.tableData[index].setStart = true;
        this.tableData[index].end_date = null;
        this.tableData[index].dateGroup = [];
      } else {
        this.tableData[index].setStart = false;
        this.tableData[index].end_date = fnCSTToYmd(
          new Date(new Date(this.tableData[index].start_date).getTime() + 24 * 3600 * 7 * 1000),
        );
        this.tableData[index].dateGroup = [this.tableData[index].start_date, this.tableData[index].end_date];
        this.$set(this.tableData, index, this.tableData[index]);
      }
    },
    // 获取设置的排期时间
    getDate(v) {
      console.log(v);
      this.tableData.map((item, index) => {
        if (this.selectList.includes(item.index)) {
          item.dateGroup = [v.start_date, v.end_date];
          item.dateType = v.dateType;
          item.end_date = v.end_date;
          item.start_date = v.start_date;
        }
        // this.$set(this.tableData,index,item);
      });
    },
    // 预览广告拆分数据
    splitPreviews() {
      // console.log(this.adSet);
      // console.log('this.audienceInfo',this.audienceInfo);
      // console.log('this.adsInfo',this.adsInfo);
      // console.log('this.adSeriesInfo',this.adSeriesInfo);
      // console.log('拆分参数',this.adSet);
      //
      if (this.adSet.budget < 1) {
        this.$message({
          type: 'warning',
          message: '预算至少$1',
        });
        return false;
      }
      let audienceInfo = JSON.parse(JSON.stringify(this.audienceInfo));
      audienceInfo.map((v) => {
        if (v.geo_locations_region) {
          v.geo_locations_region = v.geo_locations_region.map((k) => k.key);
        }
        if (v.geo_locations_city) {
          v.geo_locations_city = v.geo_locations_city.map((k) => k.key);
        }
        if (v.excluded_geo_locations_regions) {
          v.excluded_geo_locations_regions = v.excluded_geo_locations_regions.map((k) => k.key);
        }
      });
      let ad = { name: this.accountSet.ad_name };
      let creatives = this.isFbPageAd
        ? this.adsInfo
        : JSON.parse(JSON.stringify(this.formatCreativeList(this.adsInfo, this.adSeriesInfo)));
      creatives.map((item) => {
        if (item.mutiImgs) {
          item.mutiImgs = item.mutiImgs.filter((v) => v.source_id);
        }
      });
      let adSet = JSON.parse(JSON.stringify(this.adSet));
      let campaign = {};
      adSet.sk = 'timeline';
      adSet.name = this.accountSet.adset_name;
      adSet.start_date = fnCSTToYmd(new Date());
      adSet.targeting = audienceInfo;
      campaign.name = this.accountSet.campaign_name;
      campaign.pixel_event = this.adSeriesInfo.customEventType;
      campaign.website_url = this.adSeriesInfo.siteUrl;
      campaign.is_cbo = this.adSet.is_cbo;
      campaign.status = 'ACTIVE';
      campaign.pixel_id = this.adSeriesInfo.fbPixelId;
      campaign.objective = this.adSeriesInfo.objective;
      campaign.smart_promotion_type = this.adSeriesInfo.smart_promotion_type;
      campaign.conversion_domain = this.adSeriesInfo.conversion_domain;
      adSet.budget = this.$store.getters.toBaseMoney(adSet.budget);
      adSet.bid_amount = this.$store.getters.toBaseMoney(adSet.bid_amount);
      adSet.roas_average_floor = adSet.roas_average_floor ? adSet.roas_average_floor / 1 : null;
      // 开启广告系列预算优化
      // 广告组花费
      if (this.adSet.is_cbo) {
        if (this.adSet.budget_type == 'daily') {
          adSet.daily_min_spend_target = adSet.daily_min_spend_target
            ? Number(adSet.daily_min_spend_target) * 100
            : null;
          adSet.daily_spend_cap = adSet.daily_spend_cap ? Number(adSet.daily_spend_cap) * 100 : null;
          adSet.lifetime_min_spend_target = null;
          adSet.lifetime_spend_cap = null;
        } else {
          adSet.lifetime_min_spend_target = adSet.lifetime_min_spend_target
            ? Number(adSet.lifetime_min_spend_target) * 100
            : null;
          adSet.lifetime_spend_cap = adSet.lifetime_spend_cap ? Number(adSet.lifetime_spend_cap) * 100 : null;
          adSet.daily_min_spend_target = null;
          adSet.daily_spend_cap = null;
        }
      } else {
        adSet.daily_min_spend_target = null;
        adSet.daily_spend_cap = null;
        adSet.lifetime_min_spend_target = null;
        adSet.lifetime_spend_cap = null;
      }
      let dataHelp = { website_url: this.accountSet.gg_analytics };
      if (!ad.name || !adSet.name || !campaign.name) {
        this.$message({
          type: 'warning',
          message: '请检查命名规则是否为空',
        });
        return false;
      }
      let params = {
        ad: ad,
        creatives: creatives,
        adsets: adSet,
        campaign: campaign,
        dataHelp: dataHelp,
        split_type: this.accountSet.split_type,
        is_upload: true,
        account_id: this.accountId,
        split: this.split,
        splitCount: this.split_num,
        sale: this.adSeriesInfo.sale,
        site: this.adSeriesInfo.site,
      };
      this.splitParmas = { ...params };
      // console.log('拆分之前参数',this.splitParmas);
      this.$showLoading();
      splitView(this.splitParmas).then((res) => {
        // console.log(res);
        this.$hideLoading();
        if (res.code != 0) {
          this.tableData = [];
          this.campaign_name = '';
          return false;
        }
        let list = res.data.adset;
        if (!this.editedCampaignName) {
          this.campaign_name = res.data.campaign_name;
        }
        list.map((item, index) => {
          item.dateGroup = [item.start_date];
          if (this.adSet.budget_type == 'daily') {
            item.dateType = '1';
          } else {
            item.dateType = '2';
            item.end_date = fnCSTToYmd(new Date(new Date(item.start_date).getTime() + 24 * 3600 * 7 * 1000));
            item.dateGroup[1] = item.end_date;
          }
          item.index = index + 1;
          item.budget = item.budget ? this.$store.getters.toFormatedMoney(item.budget).value : 0;
          item.bid_amount = item.bid_amount ? this.$store.getters.toFormatedMoney(item.bid_amount).value : 0;
          item.daily_min = item.daily_min ? this.$store.getters.toFormatedMoney(item.daily_min).value : 0;
          item.daily_max = item.daily_max ? this.$store.getters.toFormatedMoney(item.daily_max).value : 0;
          item.status = '';
          item.visible = false;
          if (item.ads.length) {
            item.ads.map((v, i) => {
              v.index = item.index + '-' + i;
              item.parentIndex = item.index;
              v.visible = false;
              this.$set(item.ads, i, v);
            });
          }
          // this.$set(this.tableData,index,item)
        });
        this.tableData = JSON.parse(JSON.stringify(list));
        // console.log('拆分之后数据',this.tableData);
      });
    },
    setTableData() {
      let list = JSON.parse(JSON.stringify(this.tableData));
      list.map((item) => {
        item.budget = item.budget ? this.$store.getters.toBaseMoney(item.budget) : 0;
        item.bid_amount = item.bid_amount ? this.$store.getters.toBaseMoney(item.bid_amount) : 0;
        item.daily_min = item.daily_min ? this.$store.getters.toBaseMoney(item.daily_min) : 0;
        item.daily_max = item.daily_max ? this.$store.getters.toBaseMoney(item.daily_max) : 0;
        item.start_date = item.dateGroup.length ? item.dateGroup[0] : item.start_date;
        item.end_date = item.dateGroup.length == 2 ? item.dateGroup[1] : null;
      });
      this.$emit('getTableData', {
        campaign_name: this.campaign_name,
        adset: list,
      });
    },
    canSelect(row, index) {
      return row.ads && row.ads.length;
    },
    clearsplitBy() {
      this.splitBy = [];
    },
    onChageBidAmount(e) {
      console.log(e);
      if (e > 5000) {
        this.$message.info('费用控制额最大5000');
        this.$set(this.adSet, 'bid_amount', 5000);
      }
    },
    // 获取受益方
    getBebeficiary() {
      return queryConfigAdBeneficiary().then((res) => {
        return res.data;
      });
    },
  },
  mounted() {
    this.getDictsInit();
    this.getAccountSet(this.accountId);
    if (this.initData && this.initData.ad) {
      this.setTarget('OFFSITE_CONVERSIONS');
    } else {
      this.targetTypeList = [
        {
          buyingTypes: [{ desc: '默认', key: 'AUCTION' }],
          desc: '展示量',
          key: 'IMPRESSIONS',
        },
      ];
    }
  },
  created() {
    this.creatAdSet = this.$route.query.creatAdset == 'true' ? true : false;
    if (this.$route.query.campaignId) {
      this.campaignId = this.$route.query.campaignId;
      adsetForCreate({ campaignId: this.campaignId }).then((res) => {
        this.adSet.is_cbo = res.data.campaign.is_cbo;
        this.adSet.budget_type = res.data.adsets.budget_type ? res.data.adsets.budget_type.key : 'daily';
        this.adSet.budget = res.data.adsets.budget
          ? this.$store.getters.toFormatedMoney(res.data.adsets.budget).value
          : 40;
      });
    }
  },
  watch: {
    // "$store.getters.fbAccountId": function(v, ov) {
    //     // console.log('$store.getters.fbAccountId',v);
    //     this.fbAccountId = v;
    //     // this.getAccountSet(v)
    // },
    '$store.getters.fbAccountId': {
      handler(val) {
        this.fbAccountId = val;
        //  console.log('2-$store.getters.fbAccountId',val);
      },
      immediate: true,
    },
    adSet: {
      handler(val) {
        if (this.activeStep == 4) {
          this.splitPreviews();
        }
      },
      deep: true,
    },
    audienceInfo: {
      handler() {
        // console.log(this.audienceInfo);
        // this.activeStep();
      },
      deep: true,
    },
    activeStep() {
      if (this.activeStep == 4) {
        // this.getAccountSet(this.accountId);
        // this.adSeriesInfo.objective
        if (this.adSeriesInfo.business === 'FACEBOOK_PAGE') {
          this.adSet.optimization_goal = this.adSeriesInfo.objective;
        }
        this.getBebeficiary().then((res) => {
          // if(res.dsaBeneficiary && res.dsaPayor){
          this.adSet.dsa_beneficiary = this.dsa_beneficiary = res.dsaBeneficiary;
          this.adSet.dsa_payor = this.dsa_payor = res.dsaPayor;
          this.$set(this.adSet, 'dsa_beneficiary', res.dsaBeneficiary);
          this.$set(this.adSet, 'dsa_payor', res.dsaPayor);
          // }
          this.splitPreviews();
        });
        // if (adSet.daily_spend_cap >= adSet.daily_min_spend_target) {}
        // this.splitPreviews();
        let audienceInfo = JSON.parse(JSON.stringify(this.audienceInfo));
        audienceInfo.map((v) => {
          if (v.geo_locations_region) {
            v.geo_locations_region = v.geo_locations_region.map((k) => k.key);
          }
          if (v.geo_locations_city) {
            v.geo_locations_city = v.geo_locations_city.map((k) => k.key);
          }
          if (v.excluded_geo_locations_regions) {
            v.excluded_geo_locations_regions = v.excluded_geo_locations_regions.map((k) => k.key);
          }
        });
        splitNum(audienceInfo).then((res) => {
          this.splitList = res.data;
          // this.splitBy=[];
        });
        // 填充受益方默认值
      }
    },
    accountSet: {
      handler() {
        // console.log('watch accountSet',this.accountSet)
        if (this.activeStep == 4) {
          // this.splitPreviews();
        }
      },
      deep: true,
    },
    splitBy: {
      handler() {
        // console.log(this.splitBy);
        let list = [];
        let num = 1;
        this.split = this.splitBy;
        this.splitBy.map((item) => {
          let n = this.splitList.filter((v) => v.id === item);
          num = num * n[0].num;
        });
        this.split_num = num;
        if (this.activeStep == 4) {
          this.splitPreviews();
        }
      },
    },
    campaign_name() {
      this.setTableData();
    },
    tableData: {
      handler() {
        // this.$store.getters.toBaseMoney
        // let list = JSON.parse(JSON.stringify(this.tableData));
        // list.map(item=>{
        //     item.budget = item.budget?this.$store.getters.toBaseMoney(item.budget):0
        //     item.bid_amount = item.bid_amount?this.$store.getters.toBaseMoney(item.bid_amount):0
        //     item.daily_min = item.daily_min?this.$store.getters.toBaseMoney(item.daily_min):0
        //     item.daily_max = item.daily_max?this.$store.getters.toBaseMoney(item.daily_max):0
        // })
        // console.log(list);
        // this.$emit('getTableData',{
        //     campaign_name:this.campaign_name,
        //     adset:list
        // });
        this.setTableData();
        this.$emit('getSplitParmas', this.splitParmas);
      },
      deep: true,
    },
    adsChecked(val) {
      if (!val) {
        this.adSet.daily_min_spend_target = null;
        this.adSet.daily_spend_cap = null;
        this.adSet.lifetime_min_spend_target = null;
        this.adSet.lifetime_spend_cap = null;
      }
    },
    'adSeriesInfo.business'(val) {
      //   this.setTarget();
    },
  },
};
</script>
<style lang="scss" scoped>
.blod {
  font-weight: bold;
}

.el-drawer.rtl {
  outline: none;

  * {
    outline: none;
  }
}

img {
  width: 100%;
  height: 100%;
}

.drawerCon {
  height: calc(100vh - 150px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    // height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}

.demo-drawer__footer {
  text-align: center;
  padding: 15px;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trackCreat {
  .el-form {
    .el-input-group {
      width: 40%;
    }
  }

  .tips {
    font-size: 12px;
    color: #999;
    // margin-top: 5px;
  }

  .previews {
    flex: 200px 0 0;
    background: #fff;
    background-color: rgb(248, 251, 255);
  }

  .el-radio__label {
    width: 200px;
  }

  .el-radio {
    margin-right: 10px;
  }

  .timeChoose {
    position: relative;
    width: 260px;
    top: -15px;
    cursor: pointer;

    // overflow: hidden;
    // height: 40px;
    .el-range-editor,
    .el-date-editor {
      position: absolute;
      opacity: 0;
      z-index: 222;
      left: 20px;
      top: 0;
      height: 18px;
      cursor: pointer;
    }

    .setTime {
      position: absolute;
      left: 20px;
      top: 0;
      cursor: pointer;
      // height: 40px;
      // font-size: 14px;
      // color: #fff;
    }
  }

  .el-tooltip__popper {
    width: 300px;
  }

  .trackItem {
    margin-bottom: 20px;
    background: #fff;

    .title {
      padding: 20px;
      font-size: 16px;
      border-bottom: 1px solid #ddd;
    }

    .el-input-group__prepend {
      width: 120px;
    }

    .formWrap {
      padding: 20px;

      .series {
        font-size: 14px;
        margin: 15px 0;
        color: #666;
        flex: 8;

        i {
          cursor: pointer;
          margin-left: 15px;
        }
      }

      .nameBtn {
        flex: 2;
        text-align: right;

        .el-button {
          margin: 0 8px;
          vertical-align: middle;

          img {
            width: auto;
            height: 20px;
          }
        }
      }

      .tips {
        font-size: 12px;
        color: #999;
      }

      .celue {
        margin-right: 30px;

        span {
          margin-right: 5px;
        }
      }
    }
  }
}

.daily_spend_cap {
  display: flex;
  color: #666;

  .l {
    margin-right: 20px;
    width: 240px;
  }

  .r {
    width: 240px;
  }

  ::v-deep.el-input {
    width: 100% !important;
  }

  span {
    font-size: 12px;
    color: #999;
    display: block;
  }
}
</style>
